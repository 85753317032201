import { create } from "zustand";

interface AppState {
	user: any;
	profile: any;
	grant: string;
	org: any;
	account: any;
	setProfile: (profile: any) => Promise<void>;
	setUser: (user: any) => Promise<void>;
	setGrant: (grant: string) => Promise<void>;
	setOrg: (org: any) => Promise<void>;
	setAccount: (account: any) => Promise<void>;
}

export const useStore = create<AppState>((set) => ({
	user: {},
	profile: {},
	grant: "",
	org: {},
	account: {},
	setProfile: async (profile) => set({ profile }),
	setUser: async (user) => set({ user }),
	setGrant: async (grant) => set({ grant }),
	setOrg: async (org) => set({ org }),
	setAccount: async (account) => set({ account }),
}));
